import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Grid } from "@material-ui/core";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  text: {
    whiteSpace: "pre-wrap",
  },

  root: {
    overflowX: "hidden",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "60vh",
  },

  card: {
    position: "relative",
    width: "250px",
    height: "250px",
    display: "flex",
    alignItems: "center",
    borderRadius: "20px",
    padding: "1rem",
    margin: "1rem",
    "&:hover": {
      "& $circle": {
        "& ::before": {
          background: "rgba(255, 255, 255, 0.8)",
          clipPath: "circle(150px at center)",
        },
      },
      "& $img": {
        display: "none",
      },
      "& $content": {
        left: 0,
        top: 0,
        opacity: 1,
        visibility: "visible",
        // transform: "translate(0, -10%)",
        "& h3": {
          color: "#fff",
          textTransform: "uppercase",
          fontSize: "2em",
          lineHeight: "1em",
          marginBottom: "5px",
        },
      },
    },
  },
  content: {
    position: "relative",
    width: "100%",
    textAlign: "center",
    padding: "10px 10px 10px 10px",
    opacity: 0,
    visibility: "hidden",
    pointerEvents: "none",
  },

  circle: {
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "100%",
    height: "100%",
    borderRadius: "20px",
    overflow: "hidden",
    transition: "0.5s",
    "&::before": {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      background: "rgba(0, 0, 0, 0.2)",
      clipPath: "circle(100px at center)",
      transition: "0.5s",
    },
    "&:hover": {
      "&::before": {
        clipPath: "circle(400px at center)",
        background: "rgba(255, 255, 255, 0.2)",
      },
    },
  },
  img: {
    position: "absolute !important",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "200px",
    height: "200px",
    pointerEvents: "none",
    transition: "0.5s",
    borderRadius: "100%",
  },
}));

const getWorkCardsUser = (data) => data?.allWorkCardUserJson?.edges || [];

const getWorkCardUser = (workCardUser) => ({
  ...workCardUser?.node,
  icon: getImage(workCardUser?.node?.icon),
});

const WorkCardUser = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const data = useStaticQuery(graphql`
    query {
      allWorkCardUserJson {
        edges {
          node {
            title
            description
            icon {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  `);

  const workCardsUser = getWorkCardsUser(data)
    .map((workCardUser) => getWorkCardUser(workCardUser))
    .map(({ title, description, icon }) => (
      <Grid key={t(title)} item xs={12} md={6} lg={4} className={classes.roots}>
        <div className={classes.card}>
          <div className={classes.circle}></div>
          <div className={classes.content}>
            <h3>{t(title)}</h3>
            <p
              dangerouslySetInnerHTML={{ __html: t(description) }}
              className={classes.text}
            />
          </div>
          <GatsbyImage image={icon} alt={t(title)} className={classes.img} />
        </div>
      </Grid>
    ));

  return (
    <Grid container style={{ flex: "0 0 100%" }} align="center">
      {workCardsUser}
    </Grid>
  );
};

export default WorkCardUser;
