import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { makeStyles } from "@material-ui/core/styles";
import { graphql } from "gatsby";
import { Typography } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import { Container } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import WorkCardUser from "../components/WorkCardUser";

const useStyles = makeStyles({
  background: {
    background: "#09333F",
    backgroundSize: "100% 100%",
    animation: "gradient 7s ease infinite",
    color: "#fff",
  },
  // "@keyframes gradient": {
  //   "0%": {
  //     backgroundPosition: "0% 50%",
  //   },
  //   "50%": {
  //     backgroundPosition: "100% 50%",
  //   },
  //   "100%": {
  //     backgroundPosition: "0% 50%",
  //   },
  // },
  title: {
    textAlign: "center",
    paddingBottom: "2rem",
    paddingTop: "2rem",
  },
  container: {
    paddingBottom: "2rem",
    marginBottom: "2rem",
  },
  containerCard: {},
});

export default function We() {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div className={classes.background}>
      <Navbar />
      <Container maxWidth="md" className={classes.container}>
        <Grid item xs={12}>
          <Typography variant="h2" className={classes.title}>
            {t("we.titleWe")}
          </Typography>
          <Typography variant="h4" className={classes.title}>
            {t("we.subtitleWe")}
          </Typography>
          <br />
          <Typography component="div" variant="h6">
            <div
              dangerouslySetInnerHTML={{
                __html: t("we.textSeiteWe"),
              }}
            />
          </Typography>
        </Grid>
      </Container>
      <Container maxWidth="xl" className={classes.containerCard}>
        <WorkCardUser />
      </Container>
      <Footer />
    </div>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
